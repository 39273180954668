import BaseSelect from '@/components/common/base-select/base-select'
import { getDictLists } from '@/filters/fromDict'

import fileUpdate from '../components/file-update'
export const formInfoConfig = (content) => {
  const span = 12
  return [
    {
      label: '资金方名称',
      prop: 'capitalSideName',
      span
    },
    {
      label: '资金方类型',
      prop: 'capitalSideType',
      span
    },
    {
      label: '资方产品名称',
      prop: 'productName',
      span
    },

    {
      label: '融资利率(%)',
      prop: 'productRate',
      span
    },
    {
      label: '融资额度(元)',
      prop: 'replyQuota',
      span
    },
    {
      label: '借款主体类型',
      prop: 'borrowerTypeName',
      span
    },
    {
      label: '盖章方式',
      prop: 'sealMethod',
      span
    },
    {
      label: '是否为循环额度',
      prop: 'circleQuota',
      span
    },
    {
      label: '额度有效期',
      prop: 'effectiveEndTime',
      span

    },
    {
      label: '申报状态',
      prop: 'replyStatusName',
      span

    },
    {
      label: '共借人要求',
      prop: 'borrowerRequirementName',
      span
    },
    {
      label: '资金方案担保措施',
      prop: 'guaranteeMeasures',
      span: 24,
      showAll: true
    },
    {
      label: '底层资产要求',
      prop: 'assetRequirement',
      span: 24,
      showAll: true
    },
    {
      label: '还款方式',
      prop: 'repayStyle',
      span: 24,
      showAll: true
    }
  ]
}
export const fromConfig = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      rules: [{ required: false, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商名称',
      prop: 'gysId',
      tag: BaseSelect,
      attrs: {
        filterable: true,
        placeholder: '请选择',
        options: content.gysData,
        selectedField: ['keyId', 'enterpriseName']
      }
    },
    {
      label: '审核状态',
      prop: 'reviewStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('REVIEW_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    },

    {
      label: '文件制作状态',
      prop: 'fileMakeName',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('FILE_MAKE_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    }
  ]
}
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      'show-overflow-tooltip': true
    },
    {
      label: '供应商名称',
      prop: 'gysCompanyName',
      'show-overflow-tooltip': true
    },
    {
      label: '融资金额(元)',
      prop: 'financeAmount',
      'show-overflow-tooltip': true,
      formatter: (row) => {
        return row.financeAmount
          ? row.financeAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
          : ''
      }
    },
    {
      label: '审核状态',
      prop: 'reviewStatusName',
      'show-overflow-tooltip': true
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeName',
      'show-overflow-tooltip': true
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
// 评审会批复制作
// 会议基本信息
export const financingConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '会议批次',
      prop: 'meetingBatchNo',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols
    },

    {
      label: '评审会时间',
      prop: 'meetingDate',
      type: 'DateTime',
      cols
    },
    {
      label: '业务经理',
      prop: 'busiManagerName',

      cols
    },
    {
      label: '业务分管领导',
      prop: 'businessLeaderName',

      cols
    },
    {
      label: '风控经理',
      prop: 'riskManagerName',

      cols
    }
  ]
}
// 最终方案
export const programmeConfig = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }

  return [
    {
      label: '供应商名称',
      prop: 'gysName',
      cols
    },
    // {
    //   label: '授信总额(元)',
    //   prop: 'givingCreditAmountTotal',
    //   type: 'Amount',
    //   cols
    // },
    // {
    //   label: '存量稳享付余额(元)',
    //   prop: 'stockWxfBalance',
    //   type: 'Amount',
    //   cols
    // },
    // {
    //   label: '存量稳时付余额(元)',
    //   prop: 'stockWsfBalance',
    //   type: 'Amount',
    //   cols
    // },
    // {
    //   label: '存量稳保付余额(元)',
    //   prop: 'stockWbfBalance',
    //   type: 'Amount',
    //   cols
    // },
    // TODO
    {
      label: '本次额度(元)',
      prop: 'stockWxfBalanceInThisCase',
      type: 'Amount',
      cols
    },
    {
      label: '融资期限',
      prop: 'financeTerm',
      type: 'termOfValidity',
      validTimeUnit: content.formData.financeTermUnit,
      option: getDictLists('DATE_UNIT'),
      selectedField: ['dictId', 'dictName'],
      cols
    },
    {
      label: '综合融资利率(%)',
      prop: 'financeRate',
      type: 'BL',
      cols
    },
    {
      label: '服务费率(%)',
      prop: 'serviceRate',
      type: 'BL',
      cols
    },
    {
      label: '资方成本价格(%)',
      prop: 'financeInterestRate',
      type: 'BL',
      cols
    },
    {
      label: '综合质押率(%)',
      prop: 'pledgeRate',
      type: 'BL',
      cols
    },
    {
      label: '资金方',
      prop: 'capitalSideId',
      type: 'BaseSelect',
      option: content.providertData,
      selectedField: ['keyId', 'capitalAndProductName'],
      cols
    },
    {
      label: '还款方式',
      prop: 'repayTypeCode',
      type: 'BaseSelect',
      option: getDictLists('REPAY_TYPE_STATUS'),
      selectedField: ['dictId', 'dictName'],
      cols
    },
    {
      label: '放款条件',
      prop: 'termsOfLoan',
      span: 10
    },
    {
      label: '担保措施',
      prop: 'guaranteeCondition',

      span: 24
    },
    {
      label: '反担保措施',
      prop: 'againstGuaranteeCondition',

      span: 24
    },
    {
      label: '贷后要求',
      prop: 'requestWithLoan',

      span: 24
    },
    {
      label: '其他',
      prop: 'other',

      span: 24
    }
  ]
}
// 文件生成与下载
export const fileConfig = (content) => {
  const span = 12
  return [
    {
      label: '文件名称',
      prop: 'fileName',
      attrs: {
        type: 'info',
        placeholder: '请生成或上传文件',
        generateFileId: content.formData.fileListInfo.fileId
      },
      span,
      tag: fileUpdate,
      rules: [
        { required: false, message: '请生成或上传文件', trigger: 'change' }
      ],
      on: {
        preview: (data) => {
          console.log('预览')
          content.previewShow()
        }
      }
    }
  ]
}
// 评审会问题
export const problemConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '问题描述',
      prop: 'description',
      minWidth: '150px',

      align: 'left'
    },
    {
      label: '落实情况',
      prop: 'situation',
      minWidth: '150px',

      align: 'left'
    }

  ]
}
// 风控经理信息补充
export const measuresConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '风控经理补充信息',
      prop: 'content',
      minWidth: '150px',

      align: 'left'
    }

  ]
}
