<template>
  <!-- 评审会批复补充材料制作 -->
  <div class="decisionMake" id="mainContent">
    <!-- 融资申请信息 -->
    <div class="box">
      <text-title
        index="01"
        label="会议基本信息"
        data-title="会议基本信息"
        class="modelBox"
      ></text-title>

      <form-info
        :componentList="financingConfig"
        :model="formData"
        :showbor="false"
         :count='count'
      />
    </div>

    <!-- 批复内容 -->
    <div class="box">
      <text-title
        index="02"
        label="批复内容"
        data-title="批复内容"
        class="modelBox"
      ></text-title>

        <form-info
        :componentList="programmeConfig"
        :model="formData"
        :showbor="false"
         :count='count'
      />
    </div>
    <!-- 文件生成与下载 -->
    <div class="box">
      <text-title
        index="03"
        label="文件生成与下载"
        data-title="文件生成与下载"
        class="modelBox"
      ></text-title>
      <base-form
        :componentList="fileConfig"
        :formAttrs="{
          model: formData.fileListInfo,
          labelWidth: '100px',
        }"
        ref="fileConfig"
        :showBtns="false"
        class="formData"
      />
    </div>

    <div class="bottom">
      <base-button label="关闭" type="default" @click="close"></base-button>
    </div>
    <!-- 楼层 -->
    <scroll-fool ref="scrollFool" />
     <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="fileIdCount" />
  </div>
</template>

<script>
import PreView from '@/components/pre-view/pre-view.vue'
import FormInfo from '@/components/packages/form-info/form-info-new.vue'
import { reviewMeetingApi } from '@/api/businessApi'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import {
  financingConfig,
  programmeConfig,
  fileConfig,
  problemConfig,
  measuresConfig
} from '../utils/info'
import BaseForm from '@/components/common/base-form/base-form.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'

import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: {
    TextTitle,
    BaseForm,
    ScrollFool,
    BaseButton,
    FormInfo,
    PreView
  },
  props: {
    gysData: Array,
    providertData: Array
  },
  data () {
    return {
      fileIdCount: 0,
      fileId: '',
      fileType: '',
      count: 0,
      formData: { fileListInfo: { fileId: '' } },
      textarea: '',
      fileData: {},
      generateFileId: '',
      financingData: {},
      programmeData: { validTimeUnit: '' },
      problemData: [],
      measuresData: []
    }
  },
  // 计算属性 类似于data概念
  computed: {
    problemConfig () {
      return problemConfig(this)
    },
    api () {
      return reviewMeetingApi
    },
    measuresConfig () {
      return measuresConfig(this)
    },
    fileConfig () {
      return fileConfig(this)
    },
    financingConfig () {
      return financingConfig(this)
    },
    programmeConfig () {
      return programmeConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 预览
    previewShow () {
      this.fileId = this.formData.fileListInfo.fileId
      const fileType = this.formData.fileListInfo.fileName.split('.')
      this.fileType = fileType[fileType.length - 1]
      this.fileIdCount++
    },
    close () {
      this.$router.back()
    },
    getDetail () {
      const params = {
        businessNo: this.$route.query.businessNo,
        meetingType: 'APPROVAL'
      }
      this.api.detail(params).then((res) => {
        this.formData = res.data
        if (!this.formData.fileListInfo) {
          this.formData.fileListInfo = {}
        }

        this.count++
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getDetail()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.bottom {
  width: 100%;
  height: 60px;
  display: flex;
  text-align: center;
  background: #4A7CF0;
  align-items: center;
  justify-content: center;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 5px 10px;
  .label {
    color: #2862E7;
  }
}
// 表单样式
.formData {
  background: #f9f9f6;
  padding: 20px 11px;
  margin-bottom: 18px;
  /deep/ textarea {
    border-color: #D9E5EE;
    resize: none;
  }
  /deep/ .el-textarea {
    .el-textarea__inner {
      border-color: #D9E5EE;
    }
  }

  /deep/ .el-form-item__content {
    height: auto !important;
  }
}
// 结构样式
.decisionMake {
  padding: 0;
  height: calc(100% - 41px) !important;
  background: #f9f9f6;
  overflow-y: scroll;

  .box {
    background: #fff;
    margin-top: 10px;
  }
  .formData {
    margin: 0 !important ;
    background: #fff;
  }
}
.addBtn {
  padding-right: 19px;

  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
